<template>
  <section id="bulkairtime" class="details section">
    <!-- Section Title -->
    <div class="container section-title" data-aos="fade-up">
      <h2>Details</h2>
      <div>
        <span>Bulk Airtime</span>
      </div>
    </div>
    <!-- End Section Title -->

    <div class="container">
      <div class="row gy-4 align-items-center features-item">
        <div
          class="col-md-5 order-1 order-md-2 d-flex align-items-center"
          data-aos="zoom-out"
          data-aos-delay="200"
        >
          <img src="assets/img/details-2.png" class="img-fluid" alt="" />
        </div>
        <div
          class="col-md-7 order-2 order-md-1"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <h3>Corporis temporibus maiores provident</h3>
          <p class="fst-italic">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <p>
            Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
            irure dolor in reprehenderit in voluptate velit esse cillum dolore
            eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
            proident, sunt in culpa qui officia deserunt mollit anim id est
            laborum
          </p>
          <a href="/dashboard/bulk-airtime" class="btn btn-primary"
            ><span>Let's Get Started</span><i class="bi bi-arrow-right"></i
          ></a>
        </div>
      </div>
      <!-- Features Item -->
    </div>
  </section>
</template>

<script>
export default {
  name: "AirtimeSection",
};
</script>
