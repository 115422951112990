<template>
  <footer id="footer" class="footer dark-background">
    <div class="container">
      <div class="row gy-4">
        <div class="col-lg-4 col-md-6 footer-about">
          <div class="footer-contact pt-3">
            <p>Kigali, Rwanda, Kimihurura</p>
            <p class="mt-3">
              <strong>Phone:</strong> <span>+250 785 500 006</span>
            </p>
            <p><strong>Email:</strong> <span>info@ohereza.rw</span></p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 footer-about">
          <div class="container text-center mt-4">
            <p>
              © <span>Copyright</span>
              <a href="https://ivas.rw/" target="_blank"
                ><strong class="px-1 sitename">Innovative VAS</strong></a
              >
              <span>All Rights Reserved</span>
            </p>
            <div class="credits">
              Powered by
              <a href="https://ivas.rw/" target="_blank">Innovative VAS</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 footer-about">
          <div
            class="social-links d-flex mt-4"
            style="justify-content: flex-end"
          >
            <a href="#"><i class="bi bi-twitter-x"></i></a>
            <a href="#"><i class="bi bi-facebook"></i></a>
            <a href="#"><i class="bi bi-instagram"></i></a>
            <a href="#"><i class="bi bi-linkedin"></i></a>
          </div>
        </div>
      </div>
    </div>
  </footer>

  <!-- Scroll Top -->
  <a
    href="#"
    id="scroll-top"
    class="scroll-top d-flex align-items-center justify-content-center"
    ><i class="bi bi-arrow-up-short"></i
  ></a>
</template>

<script>
export default {
  name: "LandingFooter",
};
</script>
