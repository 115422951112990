import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/Home.vue'
import SignupPage from '../views/Signup.vue'
import VerifyAccountPage from '../views/VerifyAccount.vue'
import LoginPage from '../views/Login.vue'
import DashboardPage from '../views/dashboard/Dashboard.vue'
import UsersPage from '../views/dashboard/Users.vue'
import GeneralTransactionFeesPage from '../views/dashboard/GeneralTransactionFees.vue'
import MoMoRangeFeesPage from '../views/dashboard/MoMoRangeFees.vue'
import BulkMoMoPage from '../views/dashboard/BulkMoMo.vue'
import BulkSMSPage from '../views/dashboard/BulkSMS.vue'
import BulkAirtimePage from '../views/dashboard/BulkAirtime.vue'
import BulkCashpowerPage from '../views/dashboard/BulkCashpower.vue'
import UserSettings from '../views/dashboard/Settings.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/signup',
    name: 'Signup',
    component: SignupPage
  },
  {
    path: '/verify-account',
    name: 'VerifyAccount',
    component: VerifyAccountPage,
    meta: {
      requiresAuth: true,
      auth: ['Admin', 'Client']
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/dashboard',
    name: 'AdminDashboard',
    component: DashboardPage,
    meta: {
      requiresAuth: true,
      auth: ['Admin']
    }
  },
  {
    path: '/dashboard/client',
    name: 'ClientDashboard',
    component: DashboardPage,
    meta: {
      requiresAuth: true,
      auth: ['Client']
    }
  },
  {
    path: '/dashboard/users',
    name: 'Users',
    component: UsersPage,
    meta: {
      requiresAuth: true,
      auth: ['Admin']
    }
  },
  {
    path: '/dashboard/general-transaction-fees',
    name: 'GeneralTransactionFees',
    component: GeneralTransactionFeesPage,
    meta: {
      requiresAuth: true,
      auth: ['Admin']
    }
  },
  {
    path: '/dashboard/momo-range-fees',
    name: 'MoMoRangeFees',
    component: MoMoRangeFeesPage,
    meta: {
      requiresAuth: true,
      auth: ['Admin']
    }
  },
  {
    path: '/dashboard/bulk-momo',
    name: 'BulkMoMo',
    component: BulkMoMoPage,
    meta: {
      requiresAuth: true,
      auth: ['Admin', 'Client']
    }
  },
  {
    path: '/dashboard/bulk-sms',
    name: 'BulkSMS',
    component: BulkSMSPage,
    meta: {
      requiresAuth: true,
      auth: ['Admin', 'Client']
    }
  },
  {
    path: '/dashboard/bulk-airtime',
    name: 'BulkAirtime',
    component: BulkAirtimePage,
    meta: {
      requiresAuth: true,
      auth: ['Admin', 'Client']
    }
  },
  {
    path: '/dashboard/bulk-cashpower',
    name: 'BulkCashpower',
    component: BulkCashpowerPage,
    meta: {
      requiresAuth: true,
      auth: ['Admin', 'Client']
    }
  },
  {
    path: '/dashboard/settings',
    name: 'UserSettings',
    component: UserSettings,
    meta: {
      requiresAuth: true,
      auth: ['Admin', 'Client']
    }
  },
  // {
  //   path: '*',
  //   name: 'Error404',
  //   component: LoginPage
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  const loggedInUser = localStorage.getItem('user');
  const loggedInRole = loggedInUser ? JSON.parse(loggedInUser).role : null;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!loggedInUser) {
      next({ path: '/login', query: { redirect: to.fullPath } });
      return;
    }
    else if (to.matched.some(record => record.meta.auth && !record.meta.auth.includes(loggedInRole))) {
      if (loggedInRole == 'Admin') {
        next(`/dashboard`);
        return;
      }
      next(`/dashboard/client`);
      return;
    }
  }

  next();
});


export default router