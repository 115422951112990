<template>
  <div id="wrapper">
    <Alert
      :type="this.alert_type"
      :message="this.alert_message"
      :visible="this.alert_visible"
      @dismiss="alertdismiss"
    />
    <div class="content-page">
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row-space-between">
                    <h4>BULK {{ service_type.toUpperCase() }} TRANSACTION</h4>
                    <button
                      class="btn btn-xs primary-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#add-transaction"
                    >
                      ADD NEW BULK {{ service_type.toUpperCase() }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body table-responsive">
                  <TableSkeleton v-if="getting_transactions" />
                  <table
                    v-else
                    id="datatable"
                    class="table table-condensed table-row table-hover dt-responsive table-responsive nowrap"
                  >
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Client Name</th>
                        <th>Receivers</th>
                        <th>
                          {{
                            service_type == "SMS" ? "Total SMS" : "Total Amount"
                          }}
                        </th>
                        <th v-if="service_type == 'SMS'">Message</th>
                        <th>
                          {{
                            service_type == "SMS" ? "SMS Price" : "Trans Fee"
                          }}
                        </th>
                        <th>Total Fees</th>
                        <th>Payment Account</th>
                        <th>Payment Method</th>
                        <th>Status</th>
                        <th>Created via</th>
                        <th>Created At</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody v-if="transactions.length > 0">
                      <tr
                        v-for="(transaction, index) in transactions"
                        :key="transaction.id"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>
                          <a
                            href="javascript:;"
                            class="cursor-pointer"
                            :transaction-id="transaction.id"
                            transaction-action="view-client"
                            data-bs-toggle="modal"
                            data-bs-target="#view-client"
                            @click="setTransactionInfo(transaction)"
                          >
                            {{ transaction.user?.full_names }}
                          </a>
                        </td>
                        <td>
                          <button
                            :transaction-id="transaction.id"
                            transaction-action="view-client"
                            data-bs-toggle="modal"
                            data-bs-target="#view-receivers"
                            @click="setTransactionInfo(transaction)"
                            class="btn btn-primary btn-xs cursor-pointer"
                          >
                            {{ formatNumber(transaction.receivers?.length) }}
                            Receivers
                          </button>
                        </td>
                        <td>{{ formatNumber(transaction.total_amount) }}</td>
                        <td v-if="service_type == 'SMS'">
                          {{
                            truncateText(
                              `${transaction.sms_sender_name}: ${transaction.message}`
                            )
                          }}
                        </td>
                        <td>
                          {{
                            `${
                              transaction.receivers &&
                              transaction.receivers[0] &&
                              transaction.receivers[0].fee_type != "Range"
                                ? formatNumber(
                                    transaction.receivers[0].fee_value
                                  )
                                : ""
                            } ${
                              transaction.receivers &&
                              transaction.receivers[0].fee_type != "Range"
                                ? transaction.receivers[0].fee_type
                                : "Range"
                            }`
                          }}
                        </td>
                        <td>{{ formatNumber(transaction.total_fees) }}</td>
                        <td>{{ transaction.payment_account }}</td>
                        <td>{{ transaction.payment_method }}</td>
                        <td>
                          <div
                            class="badge"
                            :class="{
                              'bg-success': transaction.status == 'Successful',
                              'bg-danger': transaction.status == 'Failed',
                              'bg-dark':
                                transaction.status == 'Pending' ||
                                transaction.status == 'Requested',
                            }"
                          >
                            {{ transaction.status }}
                          </div>
                        </td>
                        <td>{{ transaction.platform }}</td>
                        <td>{{ formatDate(transaction.created_at) }}</td>
                        <td>
                          <div class="btn-actions">
                            <a
                              v-if="service_type == 'SMS'"
                              href="javascript:;"
                              class="cursor-pointer actionbtn"
                              :transaction-id="transaction.id"
                              transaction-action="view-message"
                              data-bs-toggle="modal"
                              data-bs-target="#view-message"
                              @click="setTransactionInfo(transaction)"
                            >
                              <i class="mdi mdi-eye text-info mr-1 ml-1"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="getting_transaction_errors != ''">
                      <tr>
                        <td colspan="13">
                          <h5 class="text-center text-danger">
                            {{ getting_transaction_errors }}
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="13">
                          <h5 class="text-center text-warning">
                            No bulk {{ service_type }} transaction available
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- new transaction modal content -->
  <div
    id="add-transaction"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md modal-right">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="standard-modalLabel">
            ADD NEW BULK {{ service_type.toUpperCase() }}
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="alert alert-info alert-dismissible">
            <a
              href="javascript:;"
              class="close"
              data-dismiss="alert"
              aria-label="close"
              >&times;</a
            >
            <div class="row">
              <div class="col-md-12" v-if="service_type == 'MoMo'">
                <strong><i class="fa fa-info-circle"></i> Instruction</strong>
                <p class="lead">How to Send Money.</p>
                <ol>
                  <li>
                    Download an
                    <a href="assets/docs/momo_sample.xlsx"
                      >Excel Sample file here</a
                    >
                  </li>
                  <li>
                    The maximum amount to send at once is Ten Million Rwandan
                    Francs (10,000,000 RWF)
                  </li>
                  <li>
                    Fill the Excel File with telephone number and amount you
                    want to pay for each, Add 250 infront of the mobile Number
                  </li>
                  <li>Provide payment number.</li>
                  <li>Click Send Money.</li>
                </ol>
              </div>
              <div class="col-md-12" v-if="service_type == 'SMS'">
                <strong><i class="fa fa-info-circle"></i> Instruction</strong>
                <p class="lead">How to Send SMS.</p>
                <ol>
                  <li>
                    Download an
                    <a href="assets/docs/BulkSMS_Sample.xlsx"
                      >Excel Sample file here</a
                    >
                  </li>
                  <li>Fill the Excel File with telephone number</li>
                  <li>Provide payment number.</li>
                  <li>Click Send and Pay.</li>
                </ol>
              </div>
              <div class="col-md-12" v-if="service_type == 'Airtime'">
                <strong><i class="fa fa-info-circle"></i> Instruction</strong>
                <p class="lead">How to Send Airtime.</p>
                <ol>
                  <li>
                    Download an
                    <a href="assets/docs/Airtime_Sample.xlsx"
                      >Excel Sample file here</a
                    >
                  </li>
                  <li>
                    Fill the Excel File with telephone number and airtime amount
                    you want to pay for each
                  </li>
                  <li>Provide payment number.</li>
                  <li>Click Send Airtime.</li>
                </ol>
              </div>
              <div class="col-md-12" v-if="service_type == 'Cashpower'">
                <strong><i class="fa fa-info-circle"></i> Instruction</strong>
                <p class="lead">How to Send Cashpower.</p>
                <ol>
                  <li>
                    Download an
                    <a href="assets/docs/cashpower_sample.xlsx"
                      >Excel Sample file here</a
                    >
                  </li>
                  <li>
                    The maximum amount to send at once is Two Million Rwandan
                    Francs (2,000,000 RWF)
                  </li>
                  <li>
                    Fill the Excel File with cashpower number and amount you
                    want to pay for each.
                  </li>
                  <li>Provide payment number.</li>
                  <li>Click Send Cashpower.</li>
                </ol>
              </div>
            </div>
          </div>
          <form @submit.prevent="savetransaction">
            <div class="row" v-if="service_type === 'SMS'">
              <div class="col-md-12">
                <label class="form-label">Sender Name</label>
                <input
                  v-model="new_transaction.sms_sender_name"
                  placeholder="Sender Name"
                  class="form-control"
                />
                <!-- <MsgText
                  v-if="errors.sms_sender_name"
                  :text="errors.sms_sender_name"
                  textColor="danger"
                /> -->
              </div>
              <div class="col-md-12">
                <label class="form-label">Message</label>
                <textarea
                  v-model="new_transaction.message"
                  placeholder="Message body"
                  rows="5"
                  class="form-control"
                ></textarea>
                <MsgText
                  :text="`${
                    160 - ((new_transaction.message || '').length % 160)
                  } Characters remains of ${Math.ceil(
                    (new_transaction.message || '').length / 160
                  )} SMS`"
                  textColor="grey"
                />
                <!-- <MsgText
                  v-if="errors.message"
                  :text="errors.message"
                  textColor="danger"
                /> -->
              </div>
            </div>
            <div class="row mt-2">
              <div class="row-space-between">
                <label>Receivers</label>
                <!-- Hidden File Input -->
                <input
                  type="file"
                  ref="fileInput"
                  style="display: none"
                  @change="handleFileUpload"
                />

                <!-- Button that triggers the file input -->
                <button
                  @click.prevent="triggerFileUpload"
                  class="btn btn-primary btn-upload"
                >
                  <i class="fas fa-file-excel-o"></i> Upload Excel File
                </button>
              </div>
            </div>
            <div
              class="row"
              v-for="(receiver, index) in new_transaction.receivers"
              :key="index"
            >
              <div
                :class="{
                  'col-md-6': service_type != 'SMS',
                  'col-md-12': service_type == 'SMS',
                }"
              >
                <label for="receiver" class="form-label">
                  <i
                    @click.prevent="removeReceiver(index)"
                    class="fas fa-trash cursor-pointer text-danger"
                    v-if="new_transaction.receivers.length > 1"
                  >
                  </i>
                  Receiver {{ index + 1 }}</label
                >
                <input
                  class="form-control"
                  type="text"
                  v-model="receiver.receiver_account"
                  required=""
                  placeholder="Ex: 250789754425"
                />
              </div>
              <div class="col-md-6" v-if="service_type != 'SMS'">
                <label for="amount" class="form-label">Amount</label>
                <input
                  class="form-control"
                  type="number"
                  v-model="receiver.amount"
                  required=""
                  placeholder="Ex: 100000"
                />
              </div>
            </div>
            <div>
              <button
                @click.prevent="addReceiver"
                class="btn btn-secondary btn-add"
              >
                <i class="fas fa-add"></i> Add Receiver
              </button>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-6">
                <label for="payment_option" class="form-label"
                  >Payment Option</label
                >
                <select
                  class="form-control"
                  v-model="new_transaction.payment_method"
                  required
                >
                  <option value="">Select method</option>
                  <option value="MTN mobile money">MTN mobile money</option>
                  <!-- <option value="Airtel money">Airtel money</option> -->
                </select>
              </div>
              <div class="col-md-6">
                <label for="payment_account" class="form-label"
                  >Payment Account</label
                >
                <input
                  class="form-control"
                  type="text"
                  v-model="new_transaction.payment_account"
                  required=""
                  placeholder="Ex: 250789754425"
                />
              </div>
            </div>
            <hr />
            <div class="mt-3">
              <div v-if="service_type === 'SMS'">
                <div class="row-space-between">
                  <label>TOTAL SMS</label>
                  <span>
                    {{
                      Math.ceil((new_transaction.message || "").length / 160)
                    }}
                    SMS
                  </span>
                </div>
                <div class="row-space-between">
                  <label>TOTAL RECEIVERS</label>
                  <span
                    >{{
                      formatNumber(new_transaction.receivers.length)
                    }}
                    Receivers</span
                  >
                </div>
                <div class="row-space-between">
                  <label>SMS PRICE</label>
                  <span>{{ formatNumber(getFee()) }} / 1SMS</span>
                </div>
                <div class="row-space-between">
                  <label>TOTAL AMOUNT</label>
                  <span>
                    {{
                      formatNumber(
                        new_transaction.receivers.length *
                          Math.ceil(
                            (new_transaction.message || "").length / 160
                          ) *
                          getTotalFee(
                            new_transaction.receivers.reduce(
                              (acc, curr) => acc + curr.amount,
                              0
                            )
                          )
                      )
                    }}
                    RWF
                  </span>
                </div>
              </div>

              <div v-else>
                <div class="row-space-between">
                  <label>TOTAL RECEIVERS</label>
                  <span
                    >{{
                      formatNumber(new_transaction.receivers.length)
                    }}
                    Receivers</span
                  >
                </div>
                <div class="row-space-between">
                  <label>SERVICE FEE</label>
                  <span>
                    {{
                      formatNumber(
                        getTotalFee(
                          new_transaction.receivers.reduce(
                            (acc, curr) => acc + curr.amount,
                            0
                          )
                        )
                      )
                    }}
                    RWF
                  </span>
                </div>
                <div class="row-space-between">
                  <label>SUB - AMOUNT</label>
                  <span
                    >{{
                      formatNumber(
                        new_transaction.receivers.reduce(
                          (acc, curr) =>
                            acc +
                            (isNaN(curr.amount) || curr.amount === ""
                              ? 0
                              : parseFloat(curr.amount)),
                          0
                        )
                      )
                    }}
                    RWF
                  </span>
                </div>
                <div class="row-space-between">
                  <label>TOTAL AMOUNT</label>
                  <span>
                    {{
                      formatNumber(
                        new_transaction.receivers.reduce(
                          (acc, curr) =>
                            acc +
                            (isNaN(curr.amount) || curr.amount === ""
                              ? 0
                              : curr.amount),
                          0
                        ) +
                          getTotalFee(
                            new_transaction.receivers.reduce(
                              (acc, curr) =>
                                acc +
                                (isNaN(curr.amount) || curr.amount === ""
                                  ? 0
                                  : curr.amount),
                              0
                            )
                          )
                      )
                    }}
                    RWF
                  </span>
                </div>
              </div>

              <button type="submit" class="btn btn-success btn-submit">
                <span v-if="saving_transaction">Sending...</span>
                <span v-else>SEND BULK {{ service_type.toUpperCase() }}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>

  <div
    id="view-receivers"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="standard-modal Label">RECEIVERS</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="service_type === 'SMS'" class="sms-container">
            <blockquote class="sms">
              {{ viewed_transaction.message }}
            </blockquote>
            <div class="sender">- {{ viewed_transaction.sms_sender_name }}</div>
            <div
              v-if="
                viewed_transaction.status == 'Requested' && user.role == 'Admin'
              "
            >
              <button v-if="approving" class="btn btn-sm btn-info">
                Approving...
              </button>
              <button
                v-else
                @click="approve_message(viewed_transaction.id)"
                class="btn btn-sm btn-info"
              >
                Approve
              </button>
            </div>
          </div>
          <table
            id="datatable"
            class="table table-bordered dt-responsive table-responsive nowrap"
          >
            <thead>
              <tr>
                <th>No</th>
                <th>Receiver Account</th>
                <th v-if="service_type !== 'SMS'">Amount</th>
                <th>Fee</th>
                <th>Sub Total Fee</th>
                <th v-if="service_type !== 'SMS'">Reason</th>
                <th>Status</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody
              v-if="
                viewed_transaction.receivers &&
                viewed_transaction.receivers.length
              "
            >
              <tr
                v-for="(receiver, index) in viewed_transaction.receivers"
                :key="receiver.id"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ receiver.receiver_account }}</td>
                <td v-if="service_type !== 'SMS'">{{ receiver.amount }}</td>
                <td>{{ receiver.fee_value }}{{ receiver.fee_type }}</td>
                <td>{{ receiver.sub_total_fee }}</td>
                <td v-if="service_type !== 'SMS'">{{ receiver.reason }}</td>
                <td>
                  <div
                    class="badge"
                    :class="{
                      'bg-success': receiver.status == 'Successful',
                      'bg-danger': receiver.status == 'Failed',
                      'bg-dark':
                        receiver.status == 'pending' ||
                        receiver.status == 'Requested',
                    }"
                  >
                    {{ receiver.status }}
                  </div>
                </td>
                <td>{{ receiver.status_description }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7">No product</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>

  <div
    id="view-message"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="standard-modal Label">Message</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="service_type === 'SMS'" class="sms-container">
            <blockquote class="sms">
              {{ viewed_transaction.message }}
            </blockquote>
            <div class="sender">- {{ viewed_transaction.sms_sender_name }}</div>
            <div
              v-if="
                viewed_transaction.status == 'Requested' && user.role == 'Admin'
              "
            >
              <button v-if="approving" class="btn btn-sm btn-info">
                Approving...
              </button>
              <button
                v-else
                @click="approve_message(viewed_transaction.id)"
                class="btn btn-sm btn-info"
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>

  <div
    id="view-client"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="standard-modal Label">CLIENT INFO</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="sms-container">
            <blockquote class="sms">
              {{ viewed_transaction.user?.phone }}
            </blockquote>
            <div class="sender">
              - {{ viewed_transaction.user?.full_names }}
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>
import axios from "../helpers/axios";
import Alert from "./Alert";
import TableSkeleton from "./TableSkeleton";
import { formatNumberWithCommas, truncateText } from "../hooks";
import * as XLSX from "xlsx";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TransactionsComponent",
  components: {
    Alert,
    TableSkeleton,
  },
  props: {
    service_type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["fees", "isLoading"]),
  },
  data() {
    return {
      user: null,

      transactions: [],

      approving: false,

      statuschanging: "",
      statuschangingtransaction: false,

      getting_transactions: false,
      getting_transaction_errors: "",

      alert_type: "",
      alert_message: "",
      alert_visible: false,

      saving_transaction: false,
      saving_transaction_errors: "",
      new_transaction: {
        sms_sender_name: "",
        message: "",
        payment_method: "",
        payment_account: "",
        platform: "Web",
        receivers: [
          {
            receiver_account: "",
            amount: "",
            reason: "",
          },
        ],
      },

      viewed_transaction: {},
    };
  },
  methods: {
    ...mapActions(["fetchAndStoreFees"]),
    gettransactions() {
      this.getting_transactions = true;
      axios
        .get("api/transactions", {
          params: { service_type: this.service_type },
        })
        .then((response) => {
          if (response.data.status) {
            this.transactions = response.data.result;
          } else {
            this.alertshow("danger", response.data.message);
            this.getting_transaction_errors = response.data.message;
          }
          this.getting_transactions = false;
        })
        .catch((error) => {
          let error_message =
            error.response?.data?.message ||
            "Network error. Check your internet connection";
          this.getting_transaction_errors = error_message;
          for (const property in error.response?.data?.errors) {
            error_message += ". " + error.response.data.errors[property];
          }
          this.alertshow("danger", error_message);
          this.getting_transactions = false;
        });
    },
    savetransaction() {
      this.new_transaction.service_type = this.service_type;
      this.saving_transaction = true;
      axios
        .post("api/transactions", this.new_transaction)
        .then((response) => {
          if (response.data.status) {
            this.gettransactions();
            this.alertshow("success", response.data.message);
          } else {
            this.saving_transaction_errors = response.data.message;
            this.alertshow("danger", response.data.message);
          }
          this.saving_transaction = false;
        })
        .catch((error) => {
          let error_message = error.response?.data?.message ?? error;
          for (const property in error.response.data.errors) {
            error_message += ". " + error.response.data.errors[property];
          }
          this.saving_transaction_errors = error_message;
          this.alertshow("danger", error_message);
          this.saving_transaction = false;
        });
    },
    approve_message(id) {
      let confirmation = confirm("Do you really want to approve this sms?");
      if (!confirmation) {
        return false;
      }
      this.approving = true;
      axios
        .get("/api/sms_transaction_approve/" + id)
        .then((response) => {
          if (response.data.status) {
            this.alertshow("success", "SMS Approved Successfully");
            this.viewed_transaction.status = "Pending";
            this.gettransactions();
          } else {
            this.alertshow("danger", response.data.message);
          }
          this.approving = false;
        })
        .catch((error) => {
          let error_message = error.response.data.message;
          for (const property in error.response.data.errors) {
            error_message += ". " + error.response.data.errors[property];
          }
          this.alertshow("danger", error_message);
          this.approving = false;
        });
    },
    triggerFileUpload() {
      this.$refs.fileInput.click(); // Trigger the hidden file input's click event
    },
    handleFileUpload(event) {
      if (event.target.files?.length <= 0) {
        return false;
      }
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];

            if (!sheetName) {
              throw new Error("No sheets found in the workbook.");
            }

            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Get data as an array of arrays

            // Log the raw JSON data
            console.log("Raw JSON Data:", jsonData);

            if (jsonData.length <= 1) {
              // The first row is likely headers
              throw new Error("No data found in the sheet.");
            }

            // Assuming the first row is headers, process the subsequent rows
            const processedData = jsonData
              .slice(1) // Skip the first row (headers)
              .filter((row) => {
                const phoneNumber = row[0];
                const amount = row[1];

                // Ensure phoneNumber is defined and has a length greater than 0
                const isPhoneNumberValid =
                  phoneNumber && phoneNumber.toString().length > 0;

                // Ensure amount is defined and either has a length > 0 or service_type is "SMS"
                const isAmountValid =
                  (typeof amount === "number" && amount > 0) ||
                  this.service_type === "SMS";

                return isPhoneNumberValid && isAmountValid;
              })
              .map((row) => ({
                receiver_account: row[0], // Phone number (index 0)
                amount: row[1], // Amount (index 1)
                reason: row[2] || null, // Reason (index 2), can be null
              }));

            this.new_transaction.receivers = processedData;
          } catch (error) {
            console.error("Error processing file:", error);
            alert("Error processing file: " + error.message);
          }
        };
        reader.readAsArrayBuffer(file);
      }
    },
    addReceiver() {
      this.new_transaction.receivers.push({ receiver_account: "", amount: 0 });
    },
    removeReceiver(index) {
      this.new_transaction.receivers.splice(index, 1);
    },
    setTransactionInfo(transaction) {
      this.viewed_transaction = transaction;
    },
    validateField() {
      // Validate field logic (can be implemented with libraries like VeeValidate)
    },
    alertdismiss() {
      this.alert_visible = false;
      this.alert_type = "";
      this.alert_message = "";
    },
    alertshow(type, message) {
      this.alert_visible = true;
      this.alert_type = type;
      this.alert_message = message;
    },

    // Hook to format numbers
    truncateText(text) {
      return truncateText(text, 20);
    },

    // Hook to format numbers
    formatNumber(number) {
      return formatNumberWithCommas(number);
    },

    // Hook to format a date
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const now = new Date();
      const diff = now.getTime() - date.getTime();
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (seconds < 60) {
        return "Just now";
      } else if (minutes === 1) {
        return "1 min ago";
      } else if (minutes < 60) {
        return `${minutes} mins ago`;
      } else if (hours === 1) {
        return "1 hour ago";
      } else if (hours < 24) {
        return `${hours} hours`;
      } else if (days === 1) {
        return "Yesterday";
      } else if (days <= 7) {
        const date = new Date(timestamp);
        const options = {
          weekday: "short",
          hour: "2-digit",
          minute: "2-digit",
        };
        return date.toLocaleDateString("en-US", options);
      } else {
        const options = {
          day: "2-digit",
          month: "short",
          year: "numeric",
        };
        return date.toLocaleDateString("en-US", options);
      }
    },
    getTotalFee(subAmount) {
      // Find the specific fee for the user
      const specificFee = this.fees?.userFees?.result?.find(
        (fee) => fee.service_type === this.service_type
      );
      // If no specific fee, find the general fee
      const generalFee = this.fees?.generalFees?.result?.find(
        (fee) => fee.service_type === this.service_type
      );

      // MoMo ranges
      const momo_ranges = this.fees?.momoRanges?.result;

      // Use specificFee if it exists, otherwise use generalFee
      const selectedFee = specificFee || generalFee;

      // Initialize the service fee to 0
      let serviceFee = 0;

      // Calculate the fee based on the fee_type
      if (selectedFee) {
        if (selectedFee.fee_type === "RWF") {
          serviceFee = parseFloat(selectedFee.fee_value);
        } else if (selectedFee.fee_type === "%") {
          serviceFee = (parseFloat(selectedFee.fee_value) / 100) * subAmount;
        } else if (selectedFee.fee_type === "Range" && momo_ranges) {
          // Iterate over each receiver to calculate their individual range fee
          if (this.new_transaction?.receivers?.length) {
            this.new_transaction.receivers.forEach((receiver) => {
              const applicableRange = momo_ranges.find(
                (range) =>
                  receiver.amount >= parseFloat(range.lower_limit) &&
                  receiver.amount <= parseFloat(range.upper_limit)
              );
              if (applicableRange) {
                serviceFee += parseFloat(applicableRange.fee_value);
              }
            });
          }
        }
      }

      return serviceFee;
    },

    getFee() {
      // Find the specific fee for the user
      const specificFee = this.fees?.userFees?.result?.find(
        (fee) => fee.service_type === this.service_type
      );
      // If no specific fee, find the general fee
      const generalFee = this.fees?.generalFees?.result?.find(
        (fee) => fee.service_type === this.service_type
      );

      // Use specificFee if it exists, otherwise use generalFee
      const selectedFee = specificFee || generalFee;

      // Initialize the service fee to an empty string
      let serviceFee = "";

      // Get the fee description based on the fee_type
      if (selectedFee) {
        if (selectedFee.fee_type === "RWF") {
          serviceFee = `${selectedFee.fee_value} RWF`;
        } else if (selectedFee.fee_type === "%") {
          serviceFee = `${selectedFee.fee_value}%`;
        } else if (selectedFee.fee_type === "Range" && selectedFee.ranges) {
          serviceFee = "Range-based fee";
        }
      }

      return serviceFee;
    },
  },
  async mounted() {
    const userId = this.user?.id;
    await this.fetchAndStoreFees(userId);
  },
  created() {
    const storedUser = localStorage.getItem("user");
    this.user = JSON.parse(storedUser);
    this.gettransactions();
  },
};
</script>
<style type="text/css">
.nowrap thead th,
.nowrap tbody tr {
  white-space: nowrap;
}

.error {
  margin-top: 20px;
  font-size: 14px;
  color: #dc3545;
  text-align: center;
}
.btn-actions {
  display: flex;
  justify-content: space-between;
}
.btn-upload,
.btn-remove,
.btn-add,
.btn-submit {
  background-color: #1b7483;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}
.btn-remove:hover,
.btn-upload:hover,
.btn-add:hover,
.btn-submit:hover {
  opacity: 0.8;
}
.btn-add {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.align-items-center {
  align-items: center !important;
}
</style>
