<template>
  <div id="wrapper">
    <Header title="Bulk Cashpower" />

    <Sidebar />

    <TransactionsComponent service_type="Cashpower" />
  </div>
</template>

<script>
import TransactionsComponent from "../../components/Transactions.vue";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";

export default {
  name: "BulkCashpowerPage",
  components: {
    Header,
    Sidebar,
    TransactionsComponent,
  },
  data() {
    return {
      user: null,
    };
  },
  methods: {},
  created() {
    const storedUser = localStorage.getItem("user");
    this.user = JSON.parse(storedUser);
  },
};
</script>
