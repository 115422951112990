<template>
  <div id="wrapper">
    <Header title="Clients" />

    <Sidebar />

    <UsersComponent />
  </div>
</template>

<script>
import UsersComponent from "../../components/Users.vue";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";

export default {
  name: "UsersPage",
  components: {
    Header,
    Sidebar,
    UsersComponent,
  },
  data() {
    return {
      user: null,
    };
  },
  methods: {},
  created() {
    const storedUser = localStorage.getItem("user");
    this.user = JSON.parse(storedUser);
  },
};
</script>
