<template>
  <div class="account-pages my-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-4">
          <div class="text-left">
            <a href="#">
              <img src="assets/images/logo-dark.png" alt="" class="logo" />
            </a>
            <p class="text-muted mt-2 mb-4">Account Verification</p>
          </div>
          <div class="card">
            <div class="card-body p-4">
              <div class="text-center mb-4">
                <h4 class="text-uppercase mt-0">Verify Account</h4>
              </div>
              <form @submit.prevent="verify">
                <div class="mb-3">
                  <label for="confirmation_code" class="form-label"
                    >Confirmation Code</label
                  >
                  <input
                    v-model="payload.confirmation_code"
                    class="form-control"
                    type="text"
                    id="confirmation_code"
                    required=""
                    placeholder="Enter Confirmation Code"
                  />
                </div>
                <p :class="{ 'text-success': status, 'text-danger': !status }">
                  {{ message }}
                </p>
                <div class="mb-3 d-grid text-center">
                  <button v-if="logging" class="btn btn-primary" type="button">
                    Verifying...
                  </button>
                  <button v-else class="btn btn-primary" type="submit">
                    VERIFY ACCOUNT
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../helpers/axios";
export default {
  name: "VerifyAccountPage",
  data() {
    return {
      logging: false,
      message: "",
      status: "",
      payload: {
        phone: "",
        confirmation_code: "",
      },
    };
  },
  created() {
    this.payload.phone = JSON.parse(localStorage.getItem("user")).phone;
  },
  methods: {
    verify() {
      this.message = "";
      this.logging = true;
      this.status = "";
      axios
        .post("api/confirm_user", this.payload)
        .then((result) => {
          if (result.data.status) {
            localStorage.setItem("user", JSON.stringify(result.data.user));
            localStorage.setItem("token", JSON.stringify(result.data.token));
            this.logging = false;
            this.status = true;
            this.message = "Logging in...";
            setTimeout(() => {
              window.location.href = "/";
            }, 2000);
          } else {
            this.logging = false;
            this.status = false;
            this.message = result.data.message;
          }
        })
        .catch((error) => {
          this.message = error.response.data.message;
          for (const property in error.response.data.errors) {
            this.message += ". " + error.response.data.errors[property];
          }
          this.logging = false;
          this.status = false;
        });
    },
  },
};
</script>
<style scoped>
.logo {
  width: 15em;
  height: auto;
}
</style>
