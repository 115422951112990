<template>
  <div id="wrapper">
    <Alert
      :type="this.alert_type"
      :message="this.alert_message"
      :visible="this.alert_visible"
      @dismiss="alertdismiss"
    />
    <Header />
    <Sidebar />

    <div class="content-page">
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row justify-content-between">
                    <div class="col-md-4">
                      <div class="mt-3 mt-md-0">
                        <h4>Settings</h4>
                      </div>
                    </div>
                    <!-- end col-->
                  </div>
                  <!-- end row -->
                </div>
              </div>
              <!-- end card -->
            </div>
            <!-- end col-->
          </div>
          <!-- end row -->

          <div class="row">
            <div class="col-xl-4">
              <div class="card">
                <div class="text-center card-body">
                  <div>
                    <img
                      src="../../../public/assets/images/users/user-1.png"
                      class="rounded-circle avatar-xl img-thumbnail mb-2"
                      alt="profile-image"
                    />

                    <p class="text-muted font-13 mb-3">User Information</p>

                    <div class="text-start">
                      <p class="text-muted font-13">
                        <strong>Full Name :</strong>
                        <span class="ms-2">{{ user.full_names }}</span>
                      </p>

                      <p class="text-muted font-13">
                        <strong>Mobile :</strong
                        ><span class="ms-2">+{{ user.phone }}</span>
                      </p>

                      <p class="text-muted font-13">
                        <strong>Title :</strong>
                        <span class="ms-2">{{ user.role }}</span>
                      </p>

                      <p class="text-muted font-13">
                        <strong>Joined :</strong>
                        <span class="ms-2">{{
                          formatDate(user.created_at)
                        }}</span>
                      </p>

                      <p class="text-muted font-13">
                        <strong>Join Via :</strong>
                        <span class="ms-2">{{ user.platform }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end col -->

            <div class="col-xl-4">
              <div class="card">
                <div class="card-body">
                  <div>
                    <p class="text-muted font-13 mb-3">Change Password</p>
                    <form @submit.prevent="changepassword">
                      <div>
                        <label for="old_passowrd" class="form-label"
                          >Old Password</label
                        >
                        <input
                          v-model="password.old_password"
                          class="form-control"
                          type="password"
                          id="old_passowrd"
                          required=""
                          placeholder="..........."
                        />
                      </div>
                      <div>
                        <label for="new_password" class="form-label"
                          >New Password</label
                        >
                        <input
                          v-model="password.password"
                          class="form-control"
                          type="password"
                          id="new_password"
                          required=""
                          placeholder="..........."
                        />
                      </div>
                      <div>
                        <label for="password_confirmation" class="form-label"
                          >Confirm Password</label
                        >
                        <input
                          v-model="password.password_confirmation"
                          class="form-control"
                          type="password"
                          id="password_confirmation"
                          required=""
                          placeholder="............"
                        />
                      </div>
                      <div class="mt-3 d-grid text-center">
                        <button
                          type="button"
                          v-if="changingpassword"
                          class="btn btn-md primary-btn"
                        >
                          <span
                            class="spinner-border spinner-border-sm text-white"
                            role="status"
                          ></span>
                        </button>
                        <button
                          v-else
                          type="submit"
                          class="btn btn-md primary-btn"
                        >
                          Change Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <!-- end col -->

            <div class="col-xl-4">
              <div class="card">
                <div class="card-body">
                  <div>
                    <p class="text-muted font-13 mb-3">
                      Update Basic Information
                    </p>
                    <form @submit.prevent="updateprofile">
                      <div>
                        <label for="names" class="form-label">Full Names</label>
                        <input
                          v-model="userinfo.full_names"
                          class="form-control"
                          type="text"
                          id="names"
                          required=""
                          placeholder="Aizo"
                        />
                      </div>
                      <div>
                        <label for="phone" class="form-label">Phone</label>
                        <input
                          v-model="userinfo.phone"
                          class="form-control"
                          type="text"
                          id="phone"
                          required=""
                          placeholder="0789754425"
                        />
                      </div>
                      <div class="mt-3 d-grid text-center">
                        <button
                          button="button"
                          v-if="updatinguser"
                          class="btn btn-md primary-btn mb-4"
                        >
                          <span
                            class="spinner-border spinner-border-sm text-white"
                            role="status"
                          ></span>
                        </button>
                        <button
                          v-else
                          type="submit"
                          class="btn btn-md primary-btn"
                        >
                          Update Profile
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <!-- end col -->
          </div>
          <!-- end row -->
        </div>
        <!-- container -->
      </div>
      <!-- content -->
    </div>
  </div>
</template>
<script>
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import axios from "../../helpers/axios";
import Alert from "../../components/Alert";

export default {
  name: "UserSettings",
  components: {
    Sidebar,
    Header,
    Alert,
  },
  data() {
    return {
      user: "",

      updatinguser: false,
      updatemessage: "",
      updatingcompany: false,
      companyupdatemessage: "",
      userinfo: {
        names: "",
        phone: "",
        role: "",
      },

      changingpassword: false,
      changepasswordmessage: "",
      password: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },

      alert_type: "",
      alert_message: "",
      alert_visible: false,
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.userinfo.full_names = this.user.full_names;
    this.userinfo.phone = this.user.phone;
    this.userinfo.role = this.user.role;
  },
  mounted() {},
  methods: {
    changepassword() {
      this.changepasswordmessage = "Changing password...";
      this.changingpassword = true;
      axios
        .post("api/change_password", this.password)
        .then((response) => {
          this.changepasswordmessage = "";
          if (response.data.status) {
            this.changepasswordmessage = "Password Changed Successfully!!";
            this.alertshow("success", this.changepasswordmessage);
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            setTimeout(() => {
              window.location.href = "/login";
            }, 2000);
          } else {
            this.changepasswordmessage = response.data.message;
            this.alertshow("danger", this.changepasswordmessage);
          }
          this.changingpassword = false;
        })
        .catch((error) => {
          this.changepasswordmessage = error.response.data.message;
          for (const property in error.response.data.errors) {
            this.changepasswordmessage +=
              " " + error.response.data.errors[property];
          }
          this.changingpassword = false;
          this.alertshow("danger", this.changepasswordmessage);
        });
    },
    updateprofile() {
      this.updatemessage = "Sending Data...";
      this.updatinguser = true;
      axios
        .put(`api/users/${this.user.id}`, this.userinfo)
        .then((response) => {
          this.updatemessage = "";
          if (response.data.status) {
            var user = JSON.parse(localStorage.getItem("user"));
            user.full_names = response.data.result.full_names;
            user.phone = response.data.result.phone;
            localStorage.setItem("user", JSON.stringify(user));
            this.user = JSON.parse(localStorage.getItem("user"));
            this.updatemessage = "User Updated Successfully!!";
            this.alertshow("success", this.updatemessage);
          } else {
            this.updatemessage = response.data.message;
            this.alertshow("danger", this.updatemessage);
          }
          this.updatinguser = false;
        })
        .catch((error) => {
          this.updatemessage = error.response.data.message;
          for (const property in error.response.data.errors) {
            this.updatemessage += " " + error.response.data.errors[property];
          }
          this.alertshow("danger", this.updatemessage);
          this.updatinguser = false;
        });
    },

    // Hook to format a date
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const now = new Date();
      const diff = now.getTime() - date.getTime();
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (seconds < 60) {
        return "Just now";
      } else if (minutes === 1) {
        return "1 min ago";
      } else if (minutes < 60) {
        return `${minutes} mins ago`;
      } else if (hours === 1) {
        return "1 hour ago";
      } else if (hours < 24) {
        return `${hours} hours`;
      } else if (days === 1) {
        return "Yesterday";
      } else if (days <= 7) {
        const date = new Date(timestamp);
        const options = {
          weekday: "short",
          hour: "2-digit",
          minute: "2-digit",
        };
        return date.toLocaleDateString("en-US", options);
      } else {
        const options = {
          day: "2-digit",
          month: "short",
          year: "numeric",
        };
        return date.toLocaleDateString("en-US", options);
      }
    },

    alertdismiss() {
      this.alert_visible = false;
      this.alert_type = "";
      this.alert_message = "";
    },
    alertshow(type, message) {
      this.alert_visible = true;
      this.alert_type = type;
      this.alert_message = message;
    },
  },
};
</script>
