<template>
  <div id="wrapper">
    <Alert
      :type="this.alert_type"
      :message="this.alert_message"
      :visible="this.alert_visible"
      @dismiss="alertdismiss"
    />
    <Header title="Dashboard" />

    <Sidebar />

    <div class="content-page">
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-3 col-md-6">
              <router-link to="/dashboard/users">
                <div class="custom-card bg-white">
                  <h4 class="custom-card-title">Total Clients</h4>
                  <span class="badge">10</span>
                </div>
              </router-link>
            </div>
            <!-- end col -->

            <div class="col-xl-3 col-md-6">
              <router-link to="/dashboard/bulk-momo">
                <div class="custom-card bg-white">
                  <h4 class="custom-card-title">Ohereza MoMo</h4>
                  <span class="badge">10</span>
                </div>
              </router-link>
            </div>
            <!-- end col -->

            <div class="col-xl-3 col-md-6">
              <router-link to="/dashboard/bulk-sms">
                <div class="custom-card bg-white">
                  <h4 class="custom-card-title">Ohereza SMS</h4>
                  <span class="badge">10</span>
                </div>
              </router-link>
            </div>
            <!-- end col -->

            <div class="col-xl-3 col-md-6">
              <router-link to="/dashboard/bulk-airtime">
                <div class="custom-card bg-white">
                  <h4 class="custom-card-title">Ohereza Airtime</h4>
                  <span class="badge">10</span>
                </div>
              </router-link>
            </div>
            <!-- end col -->

            <div class="col-xl-3 col-md-6">
              <router-link to="/dashboard/bulk-cashpower">
                <div class="custom-card bg-white">
                  <h4 class="custom-card-title">Ohereza Cashpower</h4>
                  <span class="badge">10</span>
                </div>
              </router-link>
            </div>
            <!-- end col -->

            <div class="col-xl-3 col-md-6">
              <router-link to="#">
                <div class="custom-card bg-white">
                  <div class="custom-card-title">
                    <h4 class="custom-card-title">Quickpay Clients</h4>
                  </div>
                  <span class="badge">10</span>
                </div>
              </router-link>
            </div>
            <!-- end col -->

            <div class="col-xl-3 col-md-6">
              <router-link to="#">
                <div class="custom-card bg-white">
                  <h4 class="custom-card-title">Quickpay Transactions</h4>
                  <span class="badge">10</span>
                </div>
              </router-link>
            </div>
            <!-- end col -->
          </div>
          <!-- end row -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../helpers/axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Alert from "../../components/Alert";
export default {
  name: "DashboardPage",
  components: {
    Header,
    Alert,
    Sidebar,
  },
  data() {
    return {
      gettingstatistics: false,
      statistics: {
        total_orders: "-",
        total_clients: "-",
        total_items: "-",
        total_active_items: "-",
        total_vendors: "-",
        total_active_vendors: "-",
      },

      alert_type: "",
      alert_message: "",
      alert_visible: false,
    };
  },
  created() {
    this.getstatistics();
  },
  methods: {
    getstatistics() {
      this.gettingstatistics = true;
      axios
        .post("/api/statistics")
        .then((result) => {
          this.statistics = result.data;
          this.gettingstatistics = false;
        })
        .catch((error) => {
          this.gettingstatistics = false;
          this.alertshow("danger", error);
        });
    },
    alertdismiss() {
      this.alert_visible = false;
      this.alert_type = "";
      this.alert_message = "";
    },
    alertshow(type, message) {
      this.alert_visible = true;
      this.alert_type = type;
      this.alert_message = message;
    },
  },
};
</script>
