<template>
  <div id="wrapper">
    <Alert
      :type="this.alert_type"
      :message="this.alert_message"
      :visible="this.alert_visible"
      @dismiss="alertdismiss"
    />

    <Header title="MoMo Range Charges" />

    <Sidebar />

    <div class="content-page">
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8">
              <div class="card">
                <div class="card-body table-responsive">
                  <h4 class="mt-0 header-title">MOMO RANGE CHANGES</h4>
                  <TableSkeleton v-if="getting_momo_range_fees" />
                  <table
                    v-else
                    id="datatable"
                    class="table table-condensed table-row table-hover dt-responsive table-responsive nowrap"
                  >
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Minimum</th>
                        <th>Maximum</th>
                        <th>Fee Value</th>
                        <th>Added By</th>
                        <th>Created</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody v-if="momo_range_fees.length > 0">
                      <tr
                        v-for="(momo_range_fee, index) in momo_range_fees"
                        :key="momo_range_fee.id"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{ formatNumber(momo_range_fee.lower_limit) }} RWF
                        </td>
                        <td>
                          {{ formatNumber(momo_range_fee.upper_limit) }} RWF
                        </td>
                        <td>
                          {{ formatNumber(momo_range_fee.fee_value) }} RWF
                        </td>
                        <td>{{ momo_range_fee.created_by?.full_names }}</td>
                        <td>{{ formatDate(momo_range_fee.created_at) }}</td>
                        <td>
                          <div class="btn-actions">
                            <a
                              href="javascript:;"
                              class="cursor-pointer actionbtn"
                              :momo_range_fee-id="momo_range_fee.id"
                              momo_range_fee-action="edit-momorange-charge"
                              @click="editmomorangefee(momo_range_fee.id)"
                            >
                              <i
                                className="mdi mdi-pencil text-primary mr-1 ml-10"
                              ></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="getting_momo_range_fee_errors != ''">
                      <tr>
                        <td colspan="6">
                          <h5 class="text-center text-danger">
                            {{ getting_momo_range_fee_errors }}
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="6">
                          <h5 class="text-center text-warning">
                            No range fee exists.
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div v-if="editing" class="col-md-4">
              <div class="card">
                <div class="card-body table-responsive">
                  <h4>Edit MoMo Range Fee</h4>
                  <form @submit.prevent="updateMoMoRangeFee">
                    <div class="form-group">
                      <label for="lower_limit" class="form-label"
                        >Minimum</label
                      >
                      <input
                        class="form-control"
                        type="number"
                        v-model="editingrange.lower_limit"
                        placeholder="Ex: 20"
                      />
                    </div>
                    <div class="form-group">
                      <label for="upper_limit" class="form-label"
                        >Maximum</label
                      >
                      <input
                        class="form-control"
                        type="number"
                        v-model="editingrange.upper_limit"
                        placeholder="Ex: 20"
                      />
                    </div>
                    <div class="form-group">
                      <label for="fee_value" class="form-label"
                        >Fee value</label
                      >
                      <input
                        class="form-control"
                        type="number"
                        v-model="editingrange.fee_value"
                        placeholder="Ex: 20"
                      />
                    </div>
                    <div class="mt-3 text-center">
                      <button
                        v-if="updatingrange"
                        class="btn btn-primary"
                        type="button"
                      >
                        SAVING.....
                      </button>
                      <button v-else class="btn btn-primary" type="submit">
                        SET MOMO RANGE FEE
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div v-else class="col-md-4">
              <div class="card">
                <div class="card-body table-responsive">
                  <h4>Set New MoMo Range Fee</h4>
                  <form @submit.prevent="saveMoMoRangeFee">
                    <div class="form-group">
                      <label for="lower_limit" class="form-label"
                        >Minimum</label
                      >
                      <input
                        class="form-control"
                        type="number"
                        v-model="momo_range_fee.lower_limit"
                        placeholder="Ex: 20"
                      />
                    </div>
                    <div class="form-group">
                      <label for="upper_limit" class="form-label"
                        >Maximum</label
                      >
                      <input
                        class="form-control"
                        type="number"
                        v-model="momo_range_fee.upper_limit"
                        placeholder="Ex: 20"
                      />
                    </div>
                    <div class="form-group">
                      <label for="fee_value" class="form-label"
                        >Fee value</label
                      >
                      <input
                        class="form-control"
                        type="number"
                        v-model="momo_range_fee.fee_value"
                        placeholder="Ex: 20"
                      />
                    </div>
                    <div class="mt-3 text-center">
                      <button
                        v-if="save_loading"
                        class="btn btn-primary"
                        type="button"
                      >
                        SAVING.....
                      </button>
                      <button v-else class="btn btn-primary" type="submit">
                        SET MOMO RANGE FEE
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../helpers/axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Alert from "../../components/Alert";
import TableSkeleton from "../../components/TableSkeleton";
import { formatNumberWithCommas } from "../../hooks";

export default {
  name: "MoMoRangeFeesPage",
  components: {
    Header,
    Sidebar,
    Alert,
    TableSkeleton,
  },
  data() {
    return {
      user: null,

      momo_range_fees: [],

      save_loading: false,

      updatingrange: false,
      editing: false,
      editingrange: {},

      getting_momo_range_fees: false,
      getting_momo_range_fee_errors: "",

      alert_type: "",
      alert_message: "",
      alert_visible: false,

      momo_range_fee: {
        service_type: "",
        fee_type: "",
        fee_value: "",
      },
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.getMoMoRangeFees();
  },
  methods: {
    saveMoMoRangeFee() {
      this.save_loading = true;
      axios
        .post("/api/momo_range_fees", this.momo_range_fee)
        .then((response) => {
          if (response.data.status) {
            this.momo_range_fee.lower_limit = "";
            this.momo_range_fee.upper_limit = "";
            this.momo_range_fee.fee_value = "";
            this.alertshow("success", "Charge fee Saved Successfully");
            this.getMoMoRangeFees();
          } else {
            this.alertshow("danger", response.data.message);
          }
          this.save_loading = false;
        })
        .catch((error) => {
          let error_message = error.response.data.message;
          for (const property in error.response.data.errors) {
            error_message += ". " + error.response.data.errors[property];
          }
          this.alertshow("danger", error_message);
          this.save_loading = false;
        });
    },
    updateMoMoRangeFee() {
      this.updatingrange = true;
      axios
        .put("/api/momo_range_fees/" + this.editingrange.id, this.editingrange)
        .then((response) => {
          if (response.data.status) {
            this.editingrange = {};
            this.editing = false;
            this.alertshow("success", "Range Updated Successfully");
            this.getMoMoRangeFees();
          } else {
            this.alertshow("danger", response.data.message);
          }
          this.updatingrange = false;
        })
        .catch((error) => {
          let error_message = error.response?.data?.message || error;
          for (const property in error.response?.data?.errors) {
            error_message += ". " + error.response.data.errors[property];
          }
          this.alertshow("danger", error_message);
          this.updatingrange = false;
        });
    },
    getMoMoRangeFees() {
      this.getting_momo_range_fees = true;
      this.getting_momo_range_fee_errors = "";
      axios
        .get("api/momo_range_fees")
        .then((response) => {
          if (response.data.status) {
            this.momo_range_fees = response.data.result;
          } else {
            this.alertshow("danger", response.data.message);
            this.getting_momo_range_fee_errors = response.data.message;
          }
          this.getting_momo_range_fees = false;
        })
        .catch((error) => {
          let error_message = error.response.data.message;
          this.getting_momo_range_fee_errors = error.response.data.message;
          for (const property in error.response.data.errors) {
            error_message += ". " + error.response.data.errors[property];
          }
          this.alertshow("danger", error_message);
          this.getting_momo_range_fees = false;
        });
    },
    alertdismiss() {
      this.alert_visible = false;
      this.alert_type = "";
      this.alert_message = "";
    },
    alertshow(type, message) {
      this.alert_visible = true;
      this.alert_type = type;
      this.alert_message = message;
    },
    editmomorangefee(id) {
      var index = this.momo_range_fees.findIndex((x) => x.id == id);
      this.editingrange = this.momo_range_fees[index];
      this.editing = true;
    },

    // Hook to format numbers
    formatNumber(number) {
      return formatNumberWithCommas(number);
    },

    // Hook to format a date
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const now = new Date();
      const diff = now.getTime() - date.getTime();
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (seconds < 60) {
        return "Just now";
      } else if (minutes === 1) {
        return "1 min ago";
      } else if (minutes < 60) {
        return `${minutes} mins ago`;
      } else if (hours === 1) {
        return "1 hour ago";
      } else if (hours < 24) {
        return `${hours} hours`;
      } else if (days === 1) {
        return "Yesterday";
      } else if (days <= 7) {
        const date = new Date(timestamp);
        const options = {
          weekday: "short",
          hour: "2-digit",
          minute: "2-digit",
        };
        return date.toLocaleDateString("en-US", options);
      } else {
        const options = {
          day: "2-digit",
          month: "short",
          year: "numeric",
        };
        return date.toLocaleDateString("en-US", options);
      }
    },
  },
};
</script>
