<template>
  <div class="account-pages my-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-4">
          <div class="text-left">
            <a href="#">
              <img src="assets/images/logo-dark.png" alt="" class="logo" />
            </a>
            <p class="text-muted mt-2 mb-4">Ohereza Register</p>
          </div>
          <div class="card">
            <div class="card-body p-4">
              <div class="text-center mb-4">
                <h4 class="text-uppercase mt-0">Sign Up</h4>
              </div>
              <form @submit.prevent="signup">
                <div class="mb-3">
                  <label for="email" class="form-label"
                    >Full Name / Amazina yose</label
                  >
                  <input
                    v-model="payload.full_names"
                    class="form-control"
                    type="text"
                    id="full_names"
                    required=""
                    placeholder="Ex: Aizo Kini"
                  />
                </div>
                <div class="mb-3">
                  <label for="phone" class="form-label">Telephone</label>
                  <input
                    v-model="payload.phone"
                    class="form-control"
                    type="text"
                    id="phone"
                    required=""
                    placeholder="Enter Phone"
                  />
                </div>
                <div class="mb-3">
                  <label for="password" class="form-label">Password</label>
                  <input
                    v-model="payload.password"
                    class="form-control"
                    type="password"
                    required=""
                    id="password"
                    placeholder="Enter Password"
                  />
                </div>
                <div class="mb-3">
                  <label for="password" class="form-label"
                    >Confirm Password</label
                  >
                  <input
                    v-model="payload.password_confirmation"
                    class="form-control"
                    type="password"
                    required=""
                    id="password_confirmation"
                    placeholder="Re-enter Password"
                  />
                </div>
                <div class="mb-3">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="checkbox-signin"
                    />
                    <label class="form-check-label" for="checkbox-signin"
                      >Remember me</label
                    >
                  </div>
                </div>
                <p :class="{ 'text-success': status, 'text-danger': !status }">
                  {{ message }}
                </p>
                <div class="mb-3 d-grid text-center">
                  <button v-if="logging" class="btn btn-primary" type="button">
                    Loading...
                  </button>
                  <button v-else class="btn btn-primary" type="submit">
                    SIGN UP
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-center">
              <p>
                <a href="#" class="text-muted ms-1"
                  ><i class="fa fa-lock me-1"></i>Forgot your password?</a
                >
              </p>
              <p class="text-muted">
                Already have an account?
                <Router-link to="/login" class="text-dark ms-1"
                  ><b>Login</b></Router-link
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../helpers/axios";
export default {
  name: "SignupPage",
  data() {
    return {
      logging: false,
      message: "",
      status: "",
      payload: {
        full_names: "",
        phone: "",
        password: "",
        password_confirmation: "",
        role: "Client",
        platform: "Web",
      },
    };
  },
  methods: {
    signup() {
      this.message = "";
      this.logging = true;
      this.status = "";
      axios
        .post("api/register", this.payload)
        .then((result) => {
          if (result.data.status) {
            localStorage.setItem("user", JSON.stringify(result.data.user));
            this.logging = false;
            this.status = true;
            this.message = "Logging in...";
            setTimeout(() => {
              window.location.href = "/verify-account";
            }, 2000);
          } else {
            this.logging = false;
            this.status = false;
            this.message = result.data.message;
          }
        })
        .catch((error) => {
          this.message = error.response.data.message;
          for (const property in error.response.data.errors) {
            this.message += ". " + error.response.data.errors[property];
          }
          this.logging = false;
          this.status = false;
        });
    },
  },
};
</script>
<style scoped>
.logo {
  width: 15em;
  height: auto;
}
</style>
