<template>
  <header id="header" class="header d-flex align-items-center fixed-top">
    <div
      class="container-fluid container-xl position-relative d-flex align-items-center justify-content-between"
    >
      <a href="index.html" class="logo d-flex align-items-center">
        <img src="/assets/images/logo-dark.png" alt="" />
      </a>

      <nav id="navmenu" class="navmenu">
        <ul>
          <li><a href="#hero" class="active">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#bulkmomo">Bulk MoMo</a></li>
          <li><a href="#bulkairtime">Bulk Airtime</a></li>
          <li><a href="#bulksms">Bulk SMS</a></li>
          <li><a href="#bulkcashpower">Bulk Cashpower</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
        <i class="mobile-nav-toggle d-xl-none bi bi-list"></i>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "LandingHeader",
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>
