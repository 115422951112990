<template>
  <LandingPageHeader />
  <main>
    <HeroSection />
    <AboutSection />
    <MoMoSection />
    <AirtimeSection />
    <SMSSection />
    <CashpowerSection />
    <ContactSection />
  </main>
  <LandingPageFooter />
</template>
<script>
import LandingPageHeader from "../components/LandingPage/Header.vue";
import LandingPageFooter from "../components/LandingPage/Footer.vue";
import HeroSection from "@/components/LandingPage/Hero.vue";
import AboutSection from "@/components/LandingPage/About.vue";
import MoMoSection from "@/components/LandingPage/MoMo.vue";
import SMSSection from "@/components/LandingPage/SMS.vue";
import AirtimeSection from "@/components/LandingPage/Airtime.vue";
import CashpowerSection from "@/components/LandingPage/Cashpower.vue";
import ContactSection from "@/components/LandingPage/Contact.vue";
export default {
  name: "HomePage",
  components: {
    LandingPageHeader,
    LandingPageFooter,
    HeroSection,
    AboutSection,
    MoMoSection,
    SMSSection,
    AirtimeSection,
    CashpowerSection,
    ContactSection,
  },
};
</script>
