<template>
  <section id="bulksms" class="details section">
    <!-- Section Title -->
    <div class="container section-title" data-aos="fade-up">
      <h2>Details</h2>
      <div>
        <span>Bulk SMS</span>
      </div>
    </div>
    <!-- End Section Title -->

    <div class="container">
      <div class="row gy-4 align-items-center features-item">
        <div class="col-md-5 d-flex align-items-center" data-aos="zoom-out">
          <img src="assets/img/details-3.png" class="img-fluid" alt="" />
        </div>
        <div class="col-md-7" data-aos="fade-up">
          <h3>
            Sunt consequatur ad ut est nulla consectetur reiciendis animi
            voluptas
          </h3>
          <p>
            Cupiditate placeat cupiditate placeat est ipsam culpa. Delectus quia
            minima quod. Sunt saepe odit aut quia voluptatem hic voluptas dolor
            doloremque.
          </p>
          <ul>
            <li>
              <i class="bi bi-check"></i>
              <span
                >Ullamco laboris nisi ut aliquip ex ea commodo consequat.</span
              >
            </li>
            <li>
              <i class="bi bi-check"></i
              ><span>
                Duis aute irure dolor in reprehenderit in voluptate velit.</span
              >
            </li>
            <li>
              <i class="bi bi-check"></i>
              <span
                >Facilis ut et voluptatem aperiam. Autem soluta ad fugiat</span
              >.
            </li>
          </ul>
          <a href="/dashboard/bulk-sms" class="btn btn-primary"
            ><span>Let's Get Started</span><i class="bi bi-arrow-right"></i
          ></a>
        </div>
      </div>
      <!-- Features Item -->
    </div>
  </section>
</template>

<script>
export default {
  name: "SMSSection",
};
</script>
