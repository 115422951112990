<template>
  <section id="about" class="about features section">
    <div class="container" data-aos="fade-up" data-aos-delay="100">
      <div class="row align-items-xl-center gy-5">
        <div class="col-xl-5 content">
          <h3>About Us</h3>
          <h2>What's Ohereza</h2>
          <p>
            OHEREZA meaning SEND in Kinyarwanda is a combination of applications
            that allow users to send bulk services with one click at their own
            convenience brough to you by Innovative VAS a software development
            organization dedicated to empowering Rwanda's digital landscape with
            cutting-edge solutions tailored to meet the unique needs of our
            dynamic society.
          </p>
          <a href="/signup" class="read-more"
            ><span>Let's Get Started</span><i class="bi bi-arrow-right"></i
          ></a>
        </div>

        <div class="col-xl-7">
          <div class="row gy-4">
            <div
              class="col-lg-6 col-md-6"
              data-aos="fade-up"
              data-aos-delay="900"
            >
              <div class="features-item">
                <i class="bi bi-cash-coin" style="color: #b2904f"></i>
                <h3>
                  <a href="/dashboard/bulk-momo" class="stretched-link"
                    >Ohereza MoMo</a
                  >
                </h3>
              </div>
            </div>
            <!-- End Feature Item -->

            <div
              class="col-lg-6 col-md-6"
              data-aos="fade-up"
              data-aos-delay="1000"
            >
              <div class="features-item">
                <i class="bi bi-telephone" style="color: #b20969"></i>
                <h3>
                  <a href="/dashboard/bulk-airtime" class="stretched-link"
                    >Ohereza Airtime</a
                  >
                </h3>
              </div>
            </div>
            <!-- End Feature Item -->

            <div
              class="col-lg-6 col-md-6"
              data-aos="fade-up"
              data-aos-delay="1100"
            >
              <div class="features-item">
                <i class="bi bi-chat-right-text" style="color: #ff5828"></i>
                <h3>
                  <a href="/dashboard/bulk-sms" class="stretched-link"
                    >Ohereza SMS</a
                  >
                </h3>
              </div>
            </div>
            <!-- End Feature Item -->

            <div
              class="col-lg-6 col-md-6"
              data-aos="fade-up"
              data-aos-delay="1200"
            >
              <div class="features-item">
                <i class="bi bi-lightning-charge" style="color: #29cc61"></i>
                <h3>
                  <a href="/dashboard/bulk-cashpower" class="stretched-link"
                    >Ohereza Cashpower</a
                  >
                </h3>
              </div>
            </div>
            <!-- End Feature Item -->
          </div>
        </div>
      </div>
      <div class="row align-items-xl-center mt-5">
        <div class="col-xl-5 content">
          <h2>Vision</h2>
          <p>
            Our vision is to develop easy to use software applications that will
            facilitate day to day operations.
          </p>
        </div>
        <div class="col-xl-5 content">
          <h2>Mission</h2>
          <p>
            Crafting intuitive and transformative products and services that
            enrich the daily lives of every mobile and web user in Rwanda
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutSection",
};
</script>
