<template>
  <section id="bulkmomo" class="details section">
    <!-- Section Title -->
    <div class="container section-title" data-aos="fade-up">
      <h2>Details</h2>
      <div>
        <span>Bulk MoMo</span>
      </div>
    </div>
    <!-- End Section Title -->

    <div class="container">
      <div class="row gy-4 align-items-center features-item">
        <div
          class="col-md-5 d-flex align-items-center"
          data-aos="zoom-out"
          data-aos-delay="100"
        >
          <img src="assets/img/details-1.png" class="img-fluid" alt="" />
        </div>
        <div class="col-md-7" data-aos="fade-up" data-aos-delay="100">
          <h3>
            Voluptatem dignissimos provident quasi corporis voluptates sit
            assumenda.
          </h3>
          <p class="fst-italic">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <ul>
            <li>
              <i class="bi bi-check"></i
              ><span>
                Ullamco laboris nisi ut aliquip ex ea commodo consequat.</span
              >
            </li>
            <li>
              <i class="bi bi-check"></i>
              <span
                >Duis aute irure dolor in reprehenderit in voluptate
                velit.</span
              >
            </li>
            <li>
              <i class="bi bi-check"></i>
              <span>Ullam est qui quos consequatur eos accusamus.</span>
            </li>
          </ul>
          <a href="/dashboard/bulk-momo" class="btn btn-primary"
            ><span>Let's Get Started</span><i class="bi bi-arrow-right"></i
          ></a>
        </div>
      </div>
      <!-- Features Item -->
    </div>
  </section>
</template>

<script>
export default {
  name: "MoMoSection",
};
</script>
