<template>
  <div id="wrapper">
    <Alert
      :type="this.alert_type"
      :message="this.alert_message"
      :visible="this.alert_visible"
      @dismiss="alertdismiss"
    />

    <Header title="General Transaction Fees" />

    <Sidebar />

    <div class="content-page">
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8">
              <div class="card">
                <div class="card-body table-responsive">
                  <h4 class="mt-0 header-title">ALL CHARGE FEES</h4>
                  <TableSkeleton v-if="getting_general_transaction_fees" />
                  <table
                    v-else
                    id="datatable"
                    class="table table-condensed table-row table-hover dt-responsive table-responsive nowrap"
                  >
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Service</th>
                        <th>Fee Type</th>
                        <th>Fee Value</th>
                        <th>Added By</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody v-if="general_transaction_fees.length > 0">
                      <tr
                        v-for="(
                          transaction_fee, index
                        ) in general_transaction_fees"
                        :key="transaction_fee.id"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ transaction_fee.service_type }}</td>
                        <td>{{ transaction_fee.fee_type }}</td>
                        <td>
                          <span v-if="transaction_fee.fee_type === 'Range'">
                            <router-link to="/dashboard/momo-range-fees"
                              >View Charges</router-link
                            >
                          </span>
                          <span v-else>
                            {{ transaction_fee.fee_value }}
                          </span>
                        </td>
                        <td>{{ transaction_fee.created_by?.full_names }}</td>
                        <td>{{ formatDate(transaction_fee.created_at) }}</td>
                        <td>
                          <div class="btn-actions">
                            <a
                              href="javascript:;"
                              class="cursor-pointer actionbtn"
                              :transaction_fee-id="transaction_fee.id"
                              transaction_fee-action="edit-transaction-charge"
                              @click="edittransactionfee(transaction_fee.id)"
                            >
                              <i
                                className="mdi mdi-pencil text-primary mr-1 ml-10"
                              ></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="getting_transaction_fee_errors != ''">
                      <tr>
                        <td colspan="6">
                          <h5 class="text-center text-danger">
                            {{ getting_transaction_fee_errors }}
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="6">
                          <h5 class="text-center text-warning">
                            No general transaction fee.
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card">
                <div class="card-body table-responsive">
                  <h4>Set General Transaction Fee</h4>
                  <form @submit.prevent="saveTransactionFee">
                    <div class="form-group">
                      <label for="service_type" class="form-label"
                        >Service Type</label
                      >
                      <select
                        class="form-control"
                        v-model="transaction_fee.service_type"
                        required=""
                      >
                        <option value="">-- Select service --</option>
                        <option value="MoMo">MoMo</option>
                        <option value="SMS">SMS</option>
                        <option value="Airtime">Airtime</option>
                        <option value="Cashpower">Cashpower</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="fee_type" class="form-label">Fee Type</label>
                      <select
                        class="form-control"
                        v-model="transaction_fee.fee_type"
                        required=""
                      >
                        <option value="">-- Select fee type --</option>
                        <option
                          v-if="transaction_fee.service_type == 'MoMo'"
                          value="Range"
                        >
                          Range
                        </option>
                        <option value="RWF">RWF</option>
                        <option value="%">%</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="fee_value" class="form-label"
                        >Fee value</label
                      >
                      <input
                        class="form-control"
                        type="number"
                        v-model="transaction_fee.fee_value"
                        placeholder="Ex: 20"
                        step="any"
                      />
                    </div>
                    <div class="mt-3 text-center">
                      <button
                        v-if="save_loading"
                        class="btn btn-primary"
                        type="button"
                      >
                        SAVING.....
                      </button>
                      <button v-else class="btn btn-primary" type="submit">
                        SET
                        {{ `${transaction_fee.service_type.toUpperCase()}` }}
                        TRANSACTION FEE
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../helpers/axios";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Alert from "../../components/Alert";
import TableSkeleton from "../../components/TableSkeleton";

export default {
  name: "GeneralTransactionFeesPage",
  components: {
    Header,
    Sidebar,
    Alert,
    TableSkeleton,
  },
  data() {
    return {
      user: null,

      general_transaction_fees: [],

      save_loading: false,

      getting_general_transaction_fees: false,
      getting_transaction_fee_errors: "",

      alert_type: "",
      alert_message: "",
      alert_visible: false,

      transaction_fee: {
        service_type: "",
        fee_type: "",
        fee_value: "",
      },
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.getGeneralTransactionFees();
  },
  methods: {
    saveTransactionFee() {
      if (
        this.transaction_fee.fee_type != "Range" &&
        this.transaction_fee.fee_value === null
      ) {
        alert("Fee value is mandatory");
        return false;
      }
      this.save_loading = true;
      axios
        .post("/api/general_transaction_fees", this.transaction_fee)
        .then((response) => {
          if (response.data.status) {
            this.transaction_fee.service_type = "";
            this.transaction_fee.fee_type = "";
            this.transaction_fee.fee_value = "";
            this.alertshow("success", "Charge fee Saved Successfully");
            this.getGeneralTransactionFees();
          } else {
            this.alertshow("danger", response.data.message);
          }
          this.save_loading = false;
        })
        .catch((error) => {
          let error_message = error.response.data.message;
          for (const property in error.response.data.errors) {
            error_message += ". " + error.response.data.errors[property];
          }
          this.alertshow("danger", error_message);
          this.save_loading = false;
        });
    },
    getGeneralTransactionFees() {
      this.getting_general_transaction_fees = true;
      this.getting_transaction_fee_errors = "";
      axios
        .get("api/general_transaction_fees")
        .then((response) => {
          if (response.data.status) {
            this.general_transaction_fees = response.data.result;
          } else {
            this.alertshow("danger", response.data.message);
            this.getting_transaction_fee_errors = response.data.message;
          }
          this.getting_general_transaction_fees = false;
        })
        .catch((error) => {
          let error_message = error.response.data.message;
          this.getting_transaction_fee_errors = error.response.data.message;
          for (const property in error.response.data.errors) {
            error_message += ". " + error.response.data.errors[property];
          }
          this.alertshow("danger", error_message);
          this.getting_general_transaction_fees = false;
        });
    },
    alertdismiss() {
      this.alert_visible = false;
      this.alert_type = "";
      this.alert_message = "";
    },
    alertshow(type, message) {
      this.alert_visible = true;
      this.alert_type = type;
      this.alert_message = message;
    },
    edittransactionfee(id) {
      var index = this.general_transaction_fees.findIndex((x) => x.id == id);
      var editingtransactionfee = this.general_transaction_fees[index];
      this.transaction_fee.service_type = editingtransactionfee.service_type;
      this.transaction_fee.fee_type = editingtransactionfee.fee_type;
      this.transaction_fee.fee_value = editingtransactionfee.fee_value;
    },

    // Hook to format a date
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const now = new Date();
      const diff = now.getTime() - date.getTime();
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (seconds < 60) {
        return "Just now";
      } else if (minutes === 1) {
        return "1 min ago";
      } else if (minutes < 60) {
        return `${minutes} mins ago`;
      } else if (hours === 1) {
        return "1 hour ago";
      } else if (hours < 24) {
        return `${hours} hours`;
      } else if (days === 1) {
        return "Yesterday";
      } else if (days <= 7) {
        const date = new Date(timestamp);
        const options = {
          weekday: "short",
          hour: "2-digit",
          minute: "2-digit",
        };
        return date.toLocaleDateString("en-US", options);
      } else {
        const options = {
          day: "2-digit",
          month: "short",
          year: "numeric",
        };
        return date.toLocaleDateString("en-US", options);
      }
    },
  },
};
</script>
