<template>
  <div v-if="!ready" class="body">
    <img src="/assets/images/loading__.gif" />
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      ready: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.ready = true;
    }, 2000);
  },
};
</script>
<style type="text/css" scoped>
.body {
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.body > img {
  width: 5em;
}
</style>
