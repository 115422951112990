<template>
  <div id="wrapper">
    <Alert
      :type="this.alert_type"
      :message="this.alert_message"
      :visible="this.alert_visible"
      @dismiss="alertdismiss"
    />
    <div class="content-page">
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body table-responsive">
                  <h4>CLIENTS</h4>
                  <TableSkeleton v-if="getting_users" />
                  <table
                    v-else
                    id="datatable"
                    class="table table-condensed table-row table-hover dt-responsive table-responsive nowrap"
                  >
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Full Name</th>
                        <th>Phone Number</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Created via</th>
                        <th>Created At</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody v-if="users.length > 0">
                      <tr v-for="(user, index) in users" :key="user.id">
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{ user.full_names }}
                        </td>
                        <td>{{ user.phone }}</td>
                        <td>{{ user.role }}</td>
                        <td>
                          <div
                            class="badge"
                            :class="{
                              'bg-success': user.active == 'Yes',
                              'bg-danger': user.active == 'No',
                            }"
                          >
                            {{ user.active == "Yes" ? "Active" : "Inactive" }}
                          </div>
                        </td>
                        <td>{{ user.platform }}</td>
                        <td>{{ formatDate(user.created_at) }}</td>
                        <td>
                          <div class="btn-actions">
                            <a
                              href="javascript:;"
                              class="cursor-pointer actionbtn"
                              :user-id="user.id"
                              user-action="edit-user"
                              @click="userStatusChange(user.id)"
                            >
                              <i
                                v-if="
                                  statuschanginguser &&
                                  statuschanging == user.id
                                "
                                class="mdi mdi-loading text-danger mr-1 ml-1"
                              ></i>
                              <i
                                v-else
                                class="mdi mr-1 ml-1"
                                :class="{
                                  'mdi-cancel text-danger':
                                    user.active == 'Yes',
                                  'mdi-check text-success': user.active == 'No',
                                }"
                              ></i>
                            </a>

                            <a
                              href="javascript:;"
                              class="cursor-pointer actionbtn"
                              :user-id="user.id"
                              user-action="edit-user"
                              @click="deleteUser(user.id)"
                            >
                              <i
                                v-if="deletinguser && deleting == user.id"
                                class="mdi mdi-loading text-danger mr-1 ml-1"
                              ></i>
                              <i
                                v-else
                                class="mdi mdi-delete-forever text-danger mr-1 ml-1"
                              ></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="getting_user_errors != ''">
                      <tr>
                        <td colspan="12">
                          <h5 class="text-center text-danger">
                            {{ getting_user_errors }}
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="12">
                          <h5 class="text-center text-warning">
                            No user available
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../helpers/axios";
import Alert from "./Alert";
import TableSkeleton from "./TableSkeleton";

export default {
  name: "UsersComponent",
  components: {
    Alert,
    TableSkeleton,
  },
  data() {
    return {
      user: null,

      users: [],

      deleting: "",
      deletinguser: false,

      statuschanging: "",
      statuschanginguser: false,

      getting_users: false,
      getting_user_errors: "",

      alert_type: "",
      alert_message: "",
      alert_visible: false,
    };
  },
  methods: {
    getusers() {
      this.getting_users = true;
      axios
        .get("api/users")
        .then((response) => {
          if (response.data.status) {
            this.users = response.data.result.data;
          } else {
            this.alertshow("danger", response.data.message);
            this.getting_user_errors = response.data.message;
          }
          this.getting_users = false;
        })
        .catch((error) => {
          let error_message = error.response.data.message;
          this.getting_user_errors = error.response.data.message;
          for (const property in error.response.data.errors) {
            error_message += ". " + error.response.data.errors[property];
          }
          this.alertshow("danger", error_message);
          this.getting_users = false;
        });
    },
    deleteUser(id) {
      var index = this.users.findIndex((x) => x.id == id);
      let user = this.users[index];
      let confirmation = confirm(
        "Do you really want to delete " + `${user.full_names}` + "?"
      );
      if (!confirmation) {
        return false;
      }
      this.deletinguser = true;
      this.deleting = id;
      axios
        .delete("/api/users/" + id)
        .then((response) => {
          if (response.data.status) {
            this.alertshow("success", "User Deleted Successfully");
            this.getusers();
          } else {
            this.alertshow("danger", response.data.message);
          }
          this.deletinguser = false;
        })
        .catch((error) => {
          let error_message = error.response.data.message;
          for (const property in error.response.data.errors) {
            error_message += ". " + error.response.data.errors[property];
          }
          this.alertshow("danger", error_message);
          this.deletinguser = false;
        });
    },
    userStatusChange(id) {
      var index = this.users.findIndex((x) => x.id == id);
      let user = this.users[index];
      let action = user.active == "No" ? "enable" : "disable";
      let confirmation = confirm(
        "Do you really want to " + action + " " + `${user.full_names}` + "?"
      );
      if (!confirmation) {
        return false;
      }
      user.active = user.active == "No" ? "Yes" : "No";
      this.statuschanging = true;
      this.statuschanginguser = id;
      axios
        .put("/api/users/" + id, user)
        .then((response) => {
          if (response.data?.status) {
            let action = user.active == "Yes" ? "enabled" : "disabled";
            this.alertshow("success", "user " + action + " Successfully");
            this.getusers();
          } else {
            this.alertshow("danger", response.data?.message);
          }
          this.statuschanging = false;
          this.statuschanginguser = "";
        })
        .catch((error) => {
          let error_message = error.response?.data?.message;
          for (const property in error.response?.data?.errors) {
            error_message += ". " + error.response?.data?.errors[property];
          }
          this.alertshow("danger", error_message);
          this.statuschanging = false;
          this.statuschanginguser = "";
        });
    },
    alertdismiss() {
      this.alert_visible = false;
      this.alert_type = "";
      this.alert_message = "";
    },
    alertshow(type, message) {
      this.alert_visible = true;
      this.alert_type = type;
      this.alert_message = message;
    },
    // Hook to format a date
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const now = new Date();
      const diff = now.getTime() - date.getTime();
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (seconds < 60) {
        return "Just now";
      } else if (minutes === 1) {
        return "1 min ago";
      } else if (minutes < 60) {
        return `${minutes} mins ago`;
      } else if (hours === 1) {
        return "1 hour ago";
      } else if (hours < 24) {
        return `${hours} hours`;
      } else if (days === 1) {
        return "Yesterday";
      } else if (days <= 7) {
        const date = new Date(timestamp);
        const options = {
          weekday: "short",
          hour: "2-digit",
          minute: "2-digit",
        };
        return date.toLocaleDateString("en-US", options);
      } else {
        const options = {
          day: "2-digit",
          month: "short",
          year: "numeric",
        };
        return date.toLocaleDateString("en-US", options);
      }
    },
  },
  created() {
    const storedUser = localStorage.getItem("user");
    this.user = JSON.parse(storedUser);
    this.getusers();
  },
};
</script>
<style type="text/css">
.nowrap thead th,
.nowrap tbody tr {
  white-space: nowrap;
}

.error {
  margin-top: 20px;
  font-size: 14px;
  color: #dc3545;
  text-align: center;
}
.btn-actions {
  display: flex;
  justify-content: space-between;
}
</style>
