<template>
  <section id="hero" class="hero section dark-background">
    <img src="/assets/img/hero-bg-2.jpg" alt="" class="hero-bg" />

    <div class="container">
      <div class="row gy-4 justify-content-between">
        <div
          class="col-lg-4 order-lg-last hero-img"
          data-aos="zoom-out"
          data-aos-delay="100"
        >
          <img
            src="/assets/img/hero-img.png"
            class="img-fluid animated"
            alt=""
          />
        </div>

        <div
          class="col-lg-6 d-flex flex-column justify-content-center"
          data-aos="fade-in"
        >
          <h1><span>Ohereza Bulk</span> Services</h1>
          <p>
            OHEREZA meaning SEND in Kinyarwanda is a combination of applications
            that allow users to send bulk services with one click at their own
            convenience brough to you by Innovative VAS a software development
            organization dedicated to empowering Rwanda's digital landscape with
            cutting-edge solutions tailored to meet the unique needs of our
            dynamic society.
          </p>
          <div class="d-flex justify-space-between">
            <a href="/login" class="btn-get-started">Login</a>
            <a href="/signup" class="btn-get-started">Get Started</a>
          </div>
        </div>
      </div>
    </div>

    <svg
      class="hero-waves"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 24 150 28 "
      preserveAspectRatio="none"
    >
      <defs>
        <path
          id="wave-path"
          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
        ></path>
      </defs>
      <g class="wave1">
        <use xlink:href="#wave-path" x="50" y="3"></use>
      </g>
      <g class="wave2">
        <use xlink:href="#wave-path" x="50" y="0"></use>
      </g>
      <g class="wave3">
        <use xlink:href="#wave-path" x="50" y="9"></use>
      </g>
    </svg>
  </section>
</template>

<script>
export default {
  name: "HeroSection",
};
</script>
