import { createStore } from 'vuex';
import axios from '../helpers/axios';

export default createStore({
    state: {
        generalFees: null,
        userFees: null,
        momoRanges: null,
        loading: true,
    },
    mutations: {
        SET_GENERAL_FEES(state, fees) {
            state.generalFees = fees;
        },
        SET_USER_FEES(state, fees) {
            state.userFees = fees;
        },
        SET_MOMO_RANGES(state, fees) {
            state.momoRanges = fees;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
    },
    actions: {
        async fetchGeneralFees({ commit }) {
            try {
                const response = await axios.get('api/general_transaction_fees');
                commit('SET_GENERAL_FEES', response.data);
                return response.data;
            } catch (error) {
                console.error('Error fetching general fees:', error);
            }
        },
        async fetchUserFees({ commit }, userId) {
            try {
                const response = await axios.get('api/specific_customer_fees', { params: { user_id: userId } });
                commit('SET_USER_FEES', response.data);
                return response.data;
            } catch (error) {
                console.error('Error fetching user fees:', error);
            }
        },
        async fetchMoMoRanges({ commit }) {
            try {
                const response = await axios.get('api/momo_range_fees');
                commit('SET_MOMO_RANGES', response.data);
                return response.data;
            } catch (error) {
                console.error('Error fetching momo ranges:', error);
            }
        },
        async fetchAndStoreFees({ dispatch, commit }, userId) {
            commit('SET_LOADING', true);
            try {
                const generalFees = await dispatch('fetchGeneralFees');
                const userFees = userId ? await dispatch('fetchUserFees', userId) : null;
                const momoRanges = await dispatch('fetchMoMoRanges');

                localStorage.setItem('general_fees', JSON.stringify(generalFees));
                localStorage.setItem('user_fees', JSON.stringify(userFees));
                localStorage.setItem('momo_ranges', JSON.stringify(momoRanges));

                return { generalFees, userFees };
            } catch (error) {
                console.error('Error fetching or storing fees:', error);

                // Attempt to retrieve from localStorage
                const cachedGeneralFees = localStorage.getItem('general_fees');
                const cachedUserFees = localStorage.getItem('user_fees');
                const cachedMoMoRanges = localStorage.getItem('momo_ranges');

                if (cachedGeneralFees || cachedUserFees) {
                    commit('SET_GENERAL_FEES', JSON.parse(cachedGeneralFees));
                    commit('SET_USER_FEES', JSON.parse(cachedUserFees));
                    commit('SET_MOMO_RANGES', JSON.parse(cachedMoMoRanges));
                }
            } finally {
                commit('SET_LOADING', false);
            }
        },
    },
    getters: {
        fees: (state) => ({ generalFees: state.generalFees, userFees: state.userFees, momoRanges: state.momoRanges }),
        isLoading: (state) => state.loading,
    },
});
